<template>
  <div class="home">
    <a class="og-website" href="https://og.ieeegtbit.org/"
      >The OG Website Link (where it all started)</a
    >
    <div class="heading">
      <div class="logos">
        <img
          src="https://api.ieeegtbit.com/api/get-photo/62f27d39adf46229f8255dab"
          alt=""
        />

        <img
          src="https://cmsadmin.amritmahotsav.nic.in/writereaddata/images/Hindi-logo1/logo.jpg"
          alt=""
        />
        
      </div>
      <img src="../../public/wallpaper.jpg" alt="" />
      
      <div class="landing-text-div">
        <h1 id="h1"></h1>
        
        <a href="https://www.ieee.org/">
          <h1 class="animate-charcter">IEEE</h1>
        </a>
      </div>
    </div>

    <div class="background">
      <!-- Events -->
      
      <h1>Events</h1>
      <div v-if="loading">Loading...</div>

      <vs-card-group v-else>
        <vs-card v-for="card in events.slice(0, 4)" :key="card._id">
          <template #title>
            <h3>{{ card.title }}</h3>
          </template>
          <template #img>
            <img
              :src="'https://api.ieeegtbit.com/api/get-photo/' + card.image"
              alt=""
            />
          </template>
          <template #text>
            <p>{{ card.description.slice(0, 150) }}</p>
            <vs-button class="btn-chat" danger :to="'/event/' + card._id">
              <span class="span"> Know More </span>
            </vs-button>
          </template>
          <template #interactions>
            <vs-button success icon v-if="card.links[0].Whatsapp">
              <img
                src="/whatsapp.png"
                class="icon"
                alt=""
                v-if="card.links[0].Whatsapp"
              />
            </vs-button>
            <vs-button danger icon v-if="card.links[0].Instagram">
              <img
                src="/instagram.png"
                class="icon"
                alt=""
                v-if="card.links[0].Instagram"
              />
            </vs-button>
            <vs-button primary icon v-if="card.links[0].Twitter">
              <img
                src="/twitter.png"
                class="icon"
                alt=""
                v-if="card.links[0].Twitter"
              />
            </vs-button>
            <vs-button warn icon v-if="card.links[0].Meet">
              <img
                src="/meet.png"
                class="icon"
                alt=""
                v-if="card.links[0].Meet"
              />
            </vs-button>
          </template>
        </vs-card>
      </vs-card-group>


      
      <!-- Blogs -->
      <h1>Blogs</h1>
      <div v-if="loading">Loading...</div>
      <vs-card-group v-else>
        <vs-card v-for="card in blogs" :key="card._id">
          <template #title>
            <h3>{{ card.Title }}</h3>
          </template>
          <template #img>
            <img :src="card.img" alt="" />
          </template>
          <template #text>
            <p>{{ card.Content.slice(0, 80) }}</p>
            <vs-button class="btn-chat" danger :to="'/blog/' + card._id">
              <span class="span"> Read More </span>
            </vs-button>
          </template>
        </vs-card>
      </vs-card-group>
    </div>
  </div>



  <!--Counter-->
</template>

<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  data() {
    return {
      events: null,
      blogs: null,
      loading: true,
      errored: false,
    };
  },
  methods: {
    getBlogs() {
      axios
        .get(
          "https://api.ieeegtbit.com/api/get-blogs-by-genre/Cloud Computing"
        )
        .then((result) => (this.blogs = result.data))
        .catch((err) => {
          console.log(err);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEvents() {
      axios
        .get("https://api.ieeegtbit.com/api/get-events")
        .then((result) => (this.events = result.data))
        .catch((err) => {
          console.log(err);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    document.addEventListener("DOMContentLoaded", function (_event) {
      // array with texts to type in typewriter
      var dataText = ["Learn.", "Live", "Love", "Laugh"];

      // type one text in the typwriter
      // keeps calling itself until the text is finished
      function typeWriter(text, i, fnCallback) {
        // chekc if text isn't finished yet
        if (i < text.length) {
          // add next character to h1
          document.getElementById("h1").innerHTML =
            text.substring(0, i + 1) + '<span aria-hidden="true"></span>';

          // wait for a while and call this function again for next character
          setTimeout(function () {
            typeWriter(text, i + 1, fnCallback);
          }, 150);
        }
        // text finished, call callback if there is a callback function
        else if (typeof fnCallback == "function") {
          // call callback after timeout
          setTimeout(fnCallback, 1000);
        }
      }
      // start a typewriter animation for a text in the dataText array
      function StartTextAnimation(i) {
        if (typeof dataText[i] == "undefined") {
          setTimeout(function () {
            StartTextAnimation(0);
          }, 800);
        }
        // check if dataText[i] exists
        if (i < dataText[i]?.length) {
          // text exists! start typewriter animation
          typeWriter(dataText[i], 0, function () {
            // after callback (and whole text has been animated), start next text
            StartTextAnimation(i + 1);
          });
        }
      }
      // start the text animation
      StartTextAnimation(0);
    });
  },
  created() {
    this.getEvents();
    this.getBlogs();
  },
};
</script>

<style scoped>
.og-website {
  display: block;
  color: white;
  text-align: center;
}
.heading {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.heading > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.landing-text-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.landing-text-div > a {
  text-decoration: none;
}
.icon {
  width: 30px;
  height: 30px;
}
.home {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.background {
  background-image: url("../../public/blog-horizontal.svg");
  background-size: cover;
}
#h1 {
  font-size: 5em;
  text-transform: uppercase;
}
h1 {
  text-align: center;
  font-size: 3.5rem;
}
.animate-charcter {
  font-size: 3rem;
}
.animate-charcter:hover {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}
.vs-card {
  height: fit-content;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
@media screen and (max-width: 800px) {
  .vs-card__group {
    width: 130vw;
    transform: translate(-11%, 0);
  }
}
.vs-card__text > p {
  margin-bottom: 2rem;
}
.heading > .logos {
  width: 100vw;
  height: 50vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  padding: 0 3rem;
}

.heading > .logos img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 50%;
}

@media(max-width:700px)
{
  .heading > .logos {
    width: 100vw;
    height: 50vh;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    top: 10px;
    padding: 0 3rem;
  }
  
  .heading > .logos img {
    width: 75px;
    height: 75px;
    object-fit: contain;
    border-radius: 50%;
  }

}
</style>
