<template>
  <footer>
    <div class="top">
      <div class="Email">
        <div class="images">
          <img
            src="https://api.ieeegtbit.com/api/get-photo/62f27d39adf46229f8255dab"
            alt="IEEE GTBIT"
            @click="
              clickImage('company/ieee-student-branch-at-gtbit/mycompany/')
            "
          />
          <img
            src="../../public/twk logo.png"
            @click="clickImage('company/the-webkrew/about/')"
            alt="Team Web Krew"
          />
        </div>
        <h1>IEEE GTBIT</h1>
        <p class="about-ieee">
          A non-profit organization, IEEE is the world's largest technical
          professional organization dedicated to advancing technology for the
          benefit of humanity.
        </p>
        <h2>DEVELOPERS</h2>
        <div class="images">
          <img
            src="https://api.ieeegtbit.com/api/get-photo/62e3f1b580bfa00ca48444d4"
            alt="Ikjyot Singh Ahluwalia"
            @click="clickImage('in/ikjyot-singh-ahluwalia-376a7320a')"
          />
          <img
            src="https://api.ieeegtbit.com/api/get-photo/62f68a2526cec087ad7a0a39"
            alt="Japneet Singh"
            @click="clickImage('in/jaskiratmokha')"
          />
          <img
            src="https://api.ieeegtbit.com/api/get-photo/62e671c36ae5f00c5d5da4a1"
            alt="Tanmaydeep Singh"
            @click="clickImage('in/tanmaydeep-singh')"
          />
          <img
            src="https://api.ieeegtbit.com/api/get-photo/62e3e91b8161f94e72a61c28"
            alt="Jaskirat Singh Mokha"
            @click="clickImage('in/jaskiratmokha')"
          />
        </div>
        Created by 
        <span class="orange"
          ><a href="https://www.linkedin.com/in/ikjyot-singh-ahluwalia-376a7320a"
            >Ikjyot</a
          ></span
        >
      </div>
      <div class="Links">
        <h1 class="h1-links">Links</h1>
        <div class="grid-links">
          <vs-navbar-item id="Home" to="/" @click="scrollTop">
            Home
          </vs-navbar-item>
          <vs-navbar-item id="About" to="/about" @click="scrollTop">
            About
          </vs-navbar-item>
          <vs-navbar-item id="Events" to="/events" @click="scrollTop">
            Events
          </vs-navbar-item>
          <vs-navbar-item id="Gallery" to="/gallery" @click="scrollTop">
            Gallery
          </vs-navbar-item>
          <vs-navbar-item
            id="Opportunities"
            to="/opportunities"
            @click="scrollTop"
          >
            Opportunities
          </vs-navbar-item>
          <vs-navbar-item
            id="Achievements"
            to="/achievements"
            @click="scrollTop"
          >
            Achievements
          </vs-navbar-item>
          <vs-navbar-item id="Contact" to="/contact" @click="scrollTop">
            Contact
          </vs-navbar-item>
        </div>
      </div>
      <div class="End">
        <h1 class="h1-links">Blogs</h1>
        <div class="grid-links">
          <vs-navbar-item
            v-for="genre in genres"
            :id="genre.name"
            :key="genre._id"
            :to="'/blogs/' + genre.name"
            @click="scrollTop"
          >
            {{ genre.name }}
          </vs-navbar-item>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p>Copyright © 2023, All Right Reserved IEEE GTBIT</p>
      <div class="socials">
        <a href="https://www.linkedin.com/company/ieee-student-branch-at-gtbit/"><i class="bx bxl-linkedin-square"></i></a>
        <a href="https://www.instagram.com/ieeegtbit/"><i class="bx bxl-instagram"></i></a>
        <a href="https://github.com/IEEE-GTBIT-SB"><i class="bx bxl-github"></i></a>
        <a href="https://twitter.com/IEEEGTBIT"><i class="bx bxl-twitter"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
export default {
  name: "Footer-",
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
    clickImage(e) {
      window.location.href = "https://www.linkedin.com/" + e;
    },
  },
  data() {
    return {
      genres: null,
    };
  },
  mounted() {
    axios
      .get("https://api.ieeegtbit.com/api/all-genres")
      .then((result) => (this.genres = result.data))
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped>
* img:hover {
  cursor: pointer;
}

.top {
  width: 100vw;
  height: fit-content;
  background-color: rgb(21, 20, 20);
  color: #878787;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
}
.Email {
  flex: 1.5;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
  flex-direction: column;
}
.about-ieee {
  line-height: 2rem;
  text-align: left;
}
.Email > h2 {
  font-size: 1.4rem;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}
.images {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.images > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0.3rem;
}

.Links {
  flex: 1.5;
  padding: 2rem;
  padding-right: 0;
}
.grid-links {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}
.grid-links > span {
  text-align: left;
}
.grid-links > span > a {
  font-size: 1.3rem;
  color: #878787;
  text-decoration: none;
}
.grid-links > span > a:hover {
  color: #ff5e14;
  cursor: pointer;
}
.orange {
  color: #ff5e14;
}
.h1-links {
  position: relative;
  text-align: left;
}
.h1-links::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 5rem;
  height: 2px;
  background-color: #ff5e14;
}
.End {
  flex: 1.5;
  padding: 2rem;
  padding-right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.bottom {
  width: 100vw;
  height: 20vh;
  background-color: rgb(32, 32, 32);
  color: #878787;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom > p {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom > div {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socials {
  font-size: 2.3rem;
}
.socials > a {
  margin: 0.8rem;
  color: #878787;
}
.socials > a:hover {
  color: #ff5e14;
  cursor: pointer;
}
.blog-links {
  margin-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 850px) {
  .top {
    flex-direction: column;
  }
  .bottom {
    flex-direction: column;
  }
  .grid-links {
    width: 80vw;
  }
}
</style>
<style scoped>
.vs-navbar__item {
  text-align: left;
}
.orange > a {
  text-decoration: none;
  color: #ff5e14;
}
</style>
