<template>
  <div id="app" :vs-theme="theme">
    <NavBar @themeChange="getTheme" class="NavBar" />
    <transition name="slide-fade" mode="out-in">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
export default {
  data: () => ({
    active: 0,
    theme: "light",
  }),
  components: {
    NavBar,
    Footer,
  },
  methods: {
    getTheme(s) {
      this.theme = s;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body{
  overflow-x: hidden;
  overflow-y:visible;
}
.slide-fade-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: rgba(var(--vs-text), 1);
}

nav {
  padding: 30px;
  
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.NavBar {
  margin-bottom: 3rem;
}
.loading {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.loading > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #3f3b3b #ffffff;
  }

  /* Chrome, Edge, and Safari */
  @media( min-width: 800px)
  {
    *::-webkit-scrollbar {
      width: 16px;
    }
  
    *::-webkit-scrollbar-track {
      background: #ffffff;
    }
  
    *::-webkit-scrollbar-thumb {
      background-color: #3f3b3b;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
  }
  
</style>
