import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: "Home",
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/wie',
    name: 'wie',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sigs/WIE.vue')
  },
  {
    path: '/cs',
    name: 'cs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sigs/CS.vue')
  },
  {
    path: '/hackgtbit',
    name: 'hackgtbit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sigs/hackGTBIT.vue')
  },
  {
    path: '/fsig',
    name: 'fsig',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sigs/FSIG.vue')
  },
  {
    path: '/members',
    name: 'members',
    component: () => import(/* webpackChunkName: "about" */ '../views/Members.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery/Gallery2View.vue')
  },
  {
    path: '/opportunities',
    name: 'opportunities',
    component: () => import(/* webpackChunkName: "about" */ '../views/Opportunities.vue')
  },
  {
    path: '/achievements',
    name: 'achievements',
    component: () => import(/* webpackChunkName: "about" */ '../views/AchievementsPage.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/blogs/:category',
    name: 'blogs',
    component: () => import( '../views/Blog/BlogsCategories.vue')
  },
  {
    path: '/blog/:id',
    name: 'blog',
    component: () => import(/* webpackChunkName: "about" */ '../views/Blog/SingleBlog.vue')
  },
  {
    path:'/events',
    name:'events',
    component: () => import('../views/Events/EventsPage.vue')
  },{
    path: '/event/:id',
    name: 'event',
    component: () => import(/* webpackChunkName: "about" */ '../views/Events/SingleEvent.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
