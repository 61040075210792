<!-- <template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
</template>
<script>
export default {
  
};
</script> -->

<template>
  <div class="center examplex" :vs-theme="themeChange">
    <vs-navbar center-collapsed v-model="active">
      <template #left>
        <img src="/logo2.png" alt="" />
        <div class="sidebar">
          <vs-button @click="activeSidebar = !activeSidebar" flat icon>
            <i class="bx bx-menu"></i>
          </vs-button>
        </div>
      </template>
      <vs-navbar-item :active="active == 'Home'" id="Home" to="/">
        Home
      </vs-navbar-item>
      <vs-navbar-item :active="active == 'About'" id="About" to="/about">
        About
      </vs-navbar-item>
      <vs-navbar-item :active="active == 'Events'" id="Events" to="/events">
        Events
      </vs-navbar-item>
      <vs-navbar-group>
        SIGs
        <template #items>
          <vs-navbar-item :active="active == 'WIE'" id="WIE" to="/wie">
            WIE
          </vs-navbar-item>
          <vs-navbar-item :active="active == 'CS'" id="CS" to="/cs">
            CS Chapter
          </vs-navbar-item>
          <vs-navbar-item :active="active == 'Hack'" id="Hack" to="/hackgtbit">
            HackGTBIT
          </vs-navbar-item>
          <vs-navbar-item :active="active == 'FSIG'" id="FSIG" to="/fsig">
            FSIG
          </vs-navbar-item>
        </template>
      </vs-navbar-group>
      <vs-navbar-item :active="active == 'Members'" id="Members" to="/members">
        Members
      </vs-navbar-item>
      <vs-navbar-item :active="active == 'Gallery'" id="Gallery" to="/gallery">
        Gallery
      </vs-navbar-item>
      <vs-navbar-group>
        Blog
        <template #items>
          <h5 class="title">Categories</h5>

          <vs-navbar-item
            v-for="genre in genres"
            :active="active == genre.name"
            :id="genre.name"
            :key="genre._id"
            :to="'/blogs/' + genre.name"
          >
            {{ genre.name }}
          </vs-navbar-item>
        </template>
      </vs-navbar-group>

      <vs-navbar-item
        :active="active == 'Opportunities'"
        id="Opportunities"
        to="/opportunities"
      >
        Opportunities
      </vs-navbar-item>
      <vs-navbar-item
        :active="active == 'Achievements'"
        id="Achievements"
        to="/achievements"
      >
        Achievements
      </vs-navbar-item>

      <vs-navbar-item @click="onButtonClick"
      :active="active == 'Magazine'"
      id=""
      to=""
    >
      Magazine
    </vs-navbar-item>
      <vs-navbar-item :active="active == 'Contact'" id="Contact" to="/contact">
        Contact
      </vs-navbar-item>
      <template #right>
        <vs-switch v-model="active5">
          <template #circle>
            <i v-if="!active5" class="bx bxs-moon"></i>
            <i v-else class="bx bxs-sun"></i>
          </template>
        </vs-switch>
      </template>
    </vs-navbar>
    <!-- Side Bar -->
    <div class="sidebar">
      <vs-sidebar absolute v-model="active" :open.sync="activeSidebar">
        <template #logo>
          <!-- ...img logo -->
        </template>
        <vs-sidebar-item id="home" to="/">
          <template #icon>
            <i class="bx bx-home"></i>
          </template>
          Home
        </vs-sidebar-item>
        <vs-sidebar-item id="market" to="/about">
          <template #icon>
            <i class="bx bx-question-mark"></i>
          </template>
          About
        </vs-sidebar-item>
        <vs-sidebar-item id="events" to="/events">
          <template #icon>
            <i class="bx bx-grid-alt"></i>
          </template>
          Events
        </vs-sidebar-item>
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class="bx bx-group"></i>
              </template>
              SIGs
            </vs-sidebar-item>
          </template>
          <vs-sidebar-item id="Instagram" to="/wie">
            <template #icon>
              <i class=""></i>
            </template>
            WIE
          </vs-sidebar-item>
          <vs-sidebar-item id="twitter" to="/cs">
            <template #icon>
              <i class=""></i>
            </template>
            CS Chapter
          </vs-sidebar-item>
          <vs-sidebar-item id="Facebook" to="/hackgtbit">
            <template #icon>
              <i class=""></i>
            </template>
            HackGTBIT
          </vs-sidebar-item>
          <vs-sidebar-item id="something" to="/fsig">
            <template #icon>
              <i class=""></i>
            </template>
            FSIG
          </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-item id="Music" to="/members">
          <template #icon>
            <i class="bx bxs-group"></i>
          </template>
          Members
        </vs-sidebar-item>
        <vs-sidebar-item id="gallery" to="/gallery">
          <template #icon>
            <i class="bx bxs-image"></i>
          </template>
          Gallery
        </vs-sidebar-item>
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class="bx bx-code-alt"></i>
              </template>
              Blog
            </vs-sidebar-item>
          </template>
          <vs-sidebar-item
            v-for="genre in genres"
            :id="genre.name"
            :key="genre._id"
            :to="'/blogs/' + genre.name"
          >
            <template #icon>
              <i class="bx bxs-hand-right"></i>
            </template>
            {{ genre.name }}
          </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-item id="donate" to="/opportunities">
          <template #icon>
            <i class="bx bxs-compass"></i>
          </template>
          Opportunities
        </vs-sidebar-item>
        <vs-sidebar-item id="drink" to="/achievements">
          <template #icon>
            <i class="bx bx-drink"></i>
          </template>
          Achievements
        </vs-sidebar-item>
        <a  @click="onButtonClick">
        <vs-sidebar-item>
          <template #icon>
            <i class="bx bx-book" @click="onButtonClick" ></i>
          </template>
         Magazine
        </vs-sidebar-item>
      </a>
        <vs-sidebar-item id="shopping" to="/contact">
          <template #icon>
            <i class="bx bxs-shopping-bags"></i>
          </template>
          Contact
        </vs-sidebar-item>
        <template #footer>
          <vs-row justify="space-between">
            <vs-avatar>
              <img src="/avatars/avatar-5.png" alt="" />
            </vs-avatar>
            <vs-avatar badge-color="danger" badge-position="top-right">
              <i class="bx bx-bell"></i>
              <template #badge> 28 </template>
            </vs-avatar>
          </vs-row>
        </template>
      </vs-sidebar>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import IEEEMagazine from '../../public/IEEEMagazine.pdf'
export default {
  name: "NavBar",
  data: () => ({
    active: "guide",
    activeSidebar: false,
    genres: null,
    loading: true,
    errored: false,
    active5: true,
    theme: "light",
    IEEEMagazine : IEEEMagazine,
  }),
  computed: {
    themeChange() {
      if (this.active5) {
        this.$emit("themeChange", "dark");
        document.body.classList.add("darken");
        return "dark";
      } else {
        this.$emit("themeChange", "light");
        document.body.classList.remove("darken");
        return "light";
      }
    },
  },
  mounted() {
    axios
      .get("https://api.ieeegtbit.com/api/all-genres")
      .then((result) => (this.genres = result.data))
      .catch((err) => {
        console.log(err);
        this.errored = true;
      })
      .finally(() => {
        this.loading = false;
      });

     
      //
       
  },
  methods: {
     onButtonClick() {
        // using Java Script method to get PDF file
        fetch(this.IEEEMagazine).then(response => {
            response.blob().then(blob => {
              console.log("downlad")
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'IEEEMagazine.pdf';
                alink.click();
            })
        })
      }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
.sidebar {
  display: none;
}
@media screen and (max-width: 1170px) {
  .sidebar {
    display: block;
  }
}
.darken {
  background-color: rgb(30, 32, 35);
}
</style>
<style scoped>
.title {
  font-size: 1.2rem;
}
</style>
